.home > div {
  width: 10rem;
}

.home-banner {
  height: 11.053333rem;
  background: linear-gradient(322deg, #63c8ff 0%, #3c41ff 100%);
  position: relative;
  overflow: hidden;
  color: #fff;
}

.home-banner .logo {
  width: 1.853333rem;
  height: 0.706667rem;
  position: absolute;
  top: 0.4rem;
  left: 0.6rem;
  background: url('../../images/logo.png') no-repeat top center;
  background-size: 100% 100%;
  z-index: 9;
}

.home-banner h1 {
  height: 0.773333rem;
  font-size: 0.826667rem;
  font-weight: 300;
  line-height: 0.773333rem;
  letter-spacing: 0.106667rem;
  margin: 2.266667rem auto 0;
  text-align: center;
  z-index: 9;
}

.home-banner h2 {
  height: 0.6rem;
  font-size: 0.36rem;
  font-weight: 300;
  line-height: 0.6rem;
  margin: 0.333333rem auto 0;
  text-align: center;
  z-index: 9;
}

.home-banner .green,
.home-banner .yellow,
.home-banner .purple {
  position: absolute;
  z-index: 9;
}

.home-banner .green {
  width: 1.04rem;
  height: 1.346667rem;
  top: 4.546667rem;
  left: 2.346667rem;
  background: url('../../images//green.png') no-repeat top center;
  background-size: 100% 100%;
  -webkit-animation: board 5s ease-in-out infinite;
  animation: board 5s ease-in-out infinite;
  -webkit-animation-delay: 0.2s;
  animation-delay: 0.2s;
}

.home-banner .yellow {
  top: 5.36rem;
  left: 7.773333rem;
  width: 1.36rem;
  height: 1.786667rem;
  background: url('../../images//yellow.png') no-repeat top center;
  background-size: 100% 100%;
  -webkit-animation: board 5s ease-in-out infinite;
  animation: board 5s ease-in-out infinite;
  -webkit-animation-delay: 0.6s;
  animation-delay: 0.6s;
}

.home-banner .purple {
  top: 7.16rem;
  left: 1.733333rem;
  width: 1.64rem;
  height: 2.186667rem;
  background: url('../../images//purple.png') no-repeat top center;
  background-size: 100% 100%;
  -webkit-animation: board 5s ease-in-out infinite;
  animation: board 5s ease-in-out infinite;
  -webkit-animation-delay: 1s;
  animation-delay: 1s;
}

@-webkit-keyframes board {
  0%, 100% {
    -webkit-transform: translateY(0.266667rem);
    transform: translateY(0.266667rem);
  }

  50% {
    -webkit-transform: translateY(-0.266667rem);
    transform: translateY(-0.266667rem);
  }
}

@keyframes board {
  0%, 100% {
    -webkit-transform: translateY(0.266667rem);
    transform: translateY(0.266667rem);
  }

  50% {
    -webkit-transform: translateY(-0.266667rem);
    transform: translateY(-0.266667rem);
  }
}

.home-banner .banner-map {
  position: absolute;
  width: 10rem;
  height: 7.346667rem;
  z-index: 1;
  background: url('../../images/banner-map.png') no-repeat top center;
  background-size: 100% 100%;
  left: 0;
  top: 3.706667rem;
}

.home-banner .banner-map .ripple {
  position: absolute;
  opacity: 0;
  z-index: 3;
}

.home-banner .banner-map .ripple .circle {
  width: 0.4rem;
  height: 0.533333rem;
  border: 0.133333rem solid #fff63f;
  opacity: 0;
  border-radius: 50%;
  display: inline-block;
  position: absolute;
}

.home-banner .banner-map .ripple .circle:nth-child(1) {
  -webkit-animation: circle-opacity 3s linear infinite;
  animation: circle-opacity 3s linear infinite;
  -webkit-animation-delay: 0.1s;
  animation-delay: 0.1s;
}

.home-banner .banner-map .ripple .circle:nth-child(2) {
  -webkit-animation: circle-opacity 3s linear infinite;
  animation: circle-opacity 3s linear infinite;
  -webkit-animation-delay: 0.8s;
  animation-delay: 0.8s;
}

.home-banner .banner-map .ripple.ripple1 {
  left: 5.92rem;
  top: 2.693333rem;
  -webkit-animation: ripple-opcity 21s linear infinite;
  animation: ripple-opcity 21s linear infinite;
  -webkit-animation-delay: 0.1s;
  animation-delay: 0.1s;
}

.home-banner .banner-map .ripple.ripple2 {
  left: 3.973333rem;
  top: 3.64rem;
  -webkit-animation: ripple-opcity 21s linear infinite;
  animation: ripple-opcity 21s linear infinite;
  -webkit-animation-delay: 3s;
  animation-delay: 3s;
}

.home-banner .banner-map .ripple.ripple3 {
  left: 4.88rem;
  top: 3.28rem;
  -webkit-animation: ripple-opcity 21s linear infinite;
  animation: ripple-opcity 21s linear infinite;
  -webkit-animation-delay: 6s;
  animation-delay: 6s;
}

.home-banner .banner-map .ripple.ripple4 {
  left: 5.573333rem;
  top: 3.386667rem;
  -webkit-animation: ripple-opcity 21s linear infinite;
  animation: ripple-opcity 21s linear infinite;
  -webkit-animation-delay: 9s;
  animation-delay: 9s;
}

.home-banner .banner-map .ripple.ripple5 {
  left: 5rem;
  top: 1.72rem;
  -webkit-animation: ripple-opcity 21s linear infinite;
  animation: ripple-opcity 21s linear infinite;
  -webkit-animation-delay: 12s;
  animation-delay: 12s;
}

.home-banner .banner-map .ripple.ripple6 {
  left: 4.933333rem;
  top: 2.573333rem;
  -webkit-animation: ripple-opcity 21s linear infinite;
  animation: ripple-opcity 21s linear infinite;
  -webkit-animation-delay: 15s;
  animation-delay: 15s;
}

.home-banner .banner-map .ripple.ripple7 {
  left: 6.373333rem;
  top: 3.253333rem;
  -webkit-animation: ripple-opcity 21s linear infinite;
  animation: ripple-opcity 21s linear infinite;
  -webkit-animation-delay: 18s;
  animation-delay: 18s;
}

@-webkit-keyframes circle-opacity {
  0% {
    opacity: 1;
    -webkit-transform: scale(0) rotate(-70deg) rotateY(30deg);
    transform: scale(0) rotate(-70deg) rotateY(30deg);
  }

  100% {
    opacity: 0;
    -webkit-transform: scale(1.4) rotate(-70deg) rotateY(30deg);
    transform: scale(1.4) rotate(-70deg) rotateY(30deg);
  }
}

@keyframes circle-opacity {
  0% {
    opacity: 1;
    -webkit-transform: scale(0) rotate(-70deg) rotateY(30deg);
    transform: scale(0) rotate(-70deg) rotateY(30deg);
  }

  100% {
    opacity: 0;
    -webkit-transform: scale(1.4) rotate(-70deg) rotateY(30deg);
    transform: scale(1.4) rotate(-70deg) rotateY(30deg);
  }
}

@-webkit-keyframes ripple-opcity {
  0%, 14.286% {
    opacity: 1;
  }

  15%, 100% {
    opacity: 0;
  }
}

@keyframes ripple-opcity {
  0%, 14.286% {
    opacity: 1;
  }

  15%, 100% {
    opacity: 0;
  }
}

.home-data {
  height: 4.16rem;
  overflow: hidden;
  position: relative;
  display: flex;
  justify-content: space-between;
}

.home-data .data-con {
  height: 4.16rem;
  width: 50%;
  position: relative;
}

.home-data .data-con .item {
  height: 4.16rem;
  width: 100%;
  position: absolute;
  left: 0;
  top: 0;
  display: flex;
  align-items: center;
  background: #fff;
  z-index: 2;
}

.home-data .data-con.left-con .item {
  -webkit-animation: dataItem 4.5s ease-in-out infinite;
  animation: dataItem 4.5s ease-in-out infinite;
  -webkit-animation-delay: 0.3s;
  animation-delay: 0.3s;
}

.home-data .data-con.left-con .item.item1 span {
  display: inline-block;
  width: 3.64rem;
  height: 2.853333rem;
  background: url('../../images/data-1.png') no-repeat top center;
  background-size: 100% 100%;
  margin-left: 0.733333rem;
}

.home-data .data-con.left-con .item.item2 {
  top: 100%;
}

.home-data .data-con.left-con .item.item2 span {
  display: inline-block;
  width: 2.453333rem;
  height: 2.906667rem;
  background: url('../../images/data-3.png') no-repeat top center;
  background-size: 100% 100%;
  margin-left: 0.733333rem;
}

.home-data .data-con.left-con .item.item3 {
  top: 200%;
}

.home-data .data-con.left-con .item.item3 span {
  display: inline-block;
  width: 3.64rem;
  height: 2.853333rem;
  background: url('../../images/data-1.png') no-repeat top center;
  background-size: 100% 100%;
  margin-left: 0.733333rem;
}

.home-data .data-con.right-con .item {
  flex-direction: row-reverse;
  -webkit-animation: dataItem 4.5s ease-in-out infinite;
  animation: dataItem 4.5s ease-in-out infinite;
  -webkit-animation-delay: 0.6s;
  animation-delay: 0.6s;
}

.home-data .data-con.right-con .item.item1 span {
  display: inline-block;
  width: 1.973333rem;
  height: 2.853333rem;
  background: url('../../images/data-2.png') no-repeat top center;
  background-size: 100% 100%;
  margin-right: 1.946667rem;
}

.home-data .data-con.right-con .item.item2 {
  top: 100%;
}

.home-data .data-con.right-con .item.item2 span {
  display: inline-block;
  width: 2.92rem;
  height: 2.92rem;
  background: url('../../images/data-4.png') no-repeat top center;
  background-size: 100% 100%;
  margin-right: 0.986667rem;
}

.home-data .data-con.right-con .item.item3 {
  top: 200%;
}

.home-data .data-con.right-con .item.item3 span {
  display: inline-block;
  width: 1.973333rem;
  height: 2.853333rem;
  background: url('../../images/data-2.png') no-repeat top center;
  background-size: 100% 100%;
  margin-right: 1.946667rem;
}

@-webkit-keyframes dataItem {
  0%, 40% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }

  50%, 90% {
    -webkit-transform: translateY(-100%);
    transform: translateY(-100%);
  }

  100% {
    -webkit-transform: translateY(-200%);
    transform: translateY(-200%);
  }
}

@keyframes dataItem {
  0%, 40% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }

  50%, 90% {
    -webkit-transform: translateY(-100%);
    transform: translateY(-100%);
  }

  100% {
    -webkit-transform: translateY(-200%);
    transform: translateY(-200%);
  }
}

.home-title {
  height: 2.96rem;
  text-align: center;
  font-size: 0.56rem;
  color: #333;
  padding-top: 1.36rem;
}

.home-title h3 {
  font-weight: 600;
}

.home-title span {
  width: 0.96rem;
  height: 0.16rem;
  display: block;
  border-radius: 0.08rem;
  margin: 0.733333rem auto 0;
  background: linear-gradient(to right, #3387ff, #33f4ff);
}

.home-intro {
  overflow: hidden;
  padding-top: 0.84rem;
  padding-bottom: 0.8rem;
}

.home-intro .am-tabs .am-tabs-default-bar-content {
  width: 9.426667rem;
  height: 1.04rem;
  margin: 0 auto;
  border-radius: 0.493333rem;
  background: rgba(51, 135, 255, 0.12);
  display: flex;
  align-items: center;
  position: relative;
}

.home-intro .am-tabs .am-tabs-default-bar-content .am-tabs-default-bar-tab {
  font-size: 0.373333rem;
  font-weight: 600;
  color: #3387ff;
  z-index: 2;
}

.home-intro .am-tabs .am-tabs-default-bar-content .am-tabs-default-bar-tab.am-tabs-default-bar-tab-active {
  color: #fff;
}

.home-intro .am-tabs .am-tabs-default-bar-content .am-tabs-default-bar-tab::after {
  height: 0;
}

.home-intro .am-tabs .am-tabs-default-bar-content .am-tabs-default-bar-underline {
  border: none;
  width: 2.133333rem;
  height: 0.8rem;
  border-radius: 0.4rem;
  z-index: 1;
  bottom: 0.12rem;
  background: linear-gradient(to right, #3387ff, #33ccff);
}

.home-intro .am-tabs .am-tabs-content-wrap .am-tabs-pane-wrap {
  width: 10rem;
  padding: 0.4rem 0.506667rem 0 0.506667rem;
}

.home-intro .intro-wrap .intro-banner {
  width: 8.986667rem;
  height: 5rem;
  display: block;
}

.home-intro .intro-wrap .intro-item {
  height: 2.253333rem;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  margin: 0.64rem auto 0;
}

.home-intro .intro-wrap .intro-item .intro-icon {
  height: 1.666667rem;
  width: 1.666667rem;
  margin-right: 0.2rem;
  margin-top: -0.2rem;
}

.home-intro .intro-wrap .intro-item .intro-con {
  width: 6.466667rem;
  height: 100%;
}

.home-intro .intro-wrap .intro-item .intro-con .title {
  color: #333;
  font-size: 0.4rem;
  font-weight: 600;
  line-height: 0.56rem;
}

.home-intro .intro-wrap .intro-item .intro-con .subtitle {
  font-size: 0.32rem;
  color: #9b9b9b;
  margin-top: 0.133333rem;
  font-weight: 400;
  line-height: 0.44rem;
}

.home-intro .intro-wrap.credit .intro-banner {
  background: url('../../images/credit.png') no-repeat top center;
  background-size: 100% 100%;
}

.home-intro .intro-wrap.credit .intro-item.item1 .intro-icon {
  background: url('../../images/credit-1.png') no-repeat top center;
  background-size: 100% 100%;
}

.home-intro .intro-wrap.credit .intro-item.item2 .intro-icon {
  background: url('../../images/credit-2.png') no-repeat top center;
  background-size: 100% 100%;
}

.home-intro .intro-wrap.credit .intro-item.item3 .intro-icon {
  background: url('../../images/credit-3.png') no-repeat top center;
  background-size: 100% 100%;
}

.home-intro .intro-wrap.credit .intro-item.item4 .intro-icon {
  background: url('../../images/credit-4.png') no-repeat top center;
  background-size: 100% 100%;
}

.home-intro .intro-wrap.sign .intro-banner {
  background: url('../../images/sign.png') no-repeat top center;
  background-size: 100% 100%;
}

.home-intro .intro-wrap.sign .intro-item.item1 .intro-icon {
  background: url('../../images/sign-1.png') no-repeat top center;
  background-size: 100% 100%;
}

.home-intro .intro-wrap.sign .intro-item.item2 .intro-icon {
  background: url('../../images/sign-2.png') no-repeat top center;
  background-size: 100% 100%;
}

.home-intro .intro-wrap.sign .intro-item.item3 .intro-icon {
  background: url('../../images/sign-3.png') no-repeat top center;
  background-size: 100% 100%;
}

.home-intro .intro-wrap.sign .intro-item.item4 .intro-icon {
  background: url('../../images/sign-4.png') no-repeat top center;
  background-size: 100% 100%;
}

.home-intro .intro-wrap.interaction .intro-banner {
  background: url('../../images/interaction.png') no-repeat top center;
  background-size: 100% 100%;
}

.home-intro .intro-wrap.interaction .intro-item.item1 .intro-icon {
  background: url('../../images/interaction-1.png') no-repeat top center;
  background-size: 100% 100%;
}

.home-intro .intro-wrap.interaction .intro-item.item2 .intro-icon {
  background: url('../../images/interaction-2.png') no-repeat top center;
  background-size: 100% 100%;
}

.home-intro .intro-wrap.interaction .intro-item.item3 .intro-icon {
  background: url('../../images/interaction-3.png') no-repeat top center;
  background-size: 100% 100%;
}

.home-intro .intro-wrap.interaction .intro-item.item4 .intro-icon {
  background: url('../../images/interaction-4.png') no-repeat top center;
  background-size: 100% 100%;
}

.home-intro .intro-wrap.custom .intro-banner {
  background: url('../../images/custom.png') no-repeat top center;
  background-size: 100% 100%;
}

.home-intro .intro-wrap.custom .intro-item.item1 .intro-icon {
  background: url('../../images/custom-1.png') no-repeat top center;
  background-size: 100% 100%;
}

.home-intro .intro-wrap.custom .intro-item.item2 .intro-icon {
  background: url('../../images/custom-2.png') no-repeat top center;
  background-size: 100% 100%;
}

.home-intro .intro-wrap.custom .intro-item.item3 .intro-icon {
  background: url('../../images/custom-3.png') no-repeat top center;
  background-size: 100% 100%;
}

.home-consumer {
  height: 8.04rem;
  background: linear-gradient(220deg, #5fdaff 0%, #3387ff 100%);
  overflow: hidden;
  position: relative;
}

.home-consumer h3 {
  font-size: 0.52rem;
  font-family: Bebas;
  color: #ffffff;
  line-height: 0.693333rem;
  letter-spacing: 0.093333rem;
  width: 100%;
  text-align: center;
  margin: 0.56rem auto 0;
  font-weight: 600;
}

.home-consumer .consumer-wrap {
  height: 5.76rem;
  width: 10rem;
  overflow: hidden;
  margin-top: 0.333333rem;
}

.home-consumer .consumer-wrap .consumer-swiper {
  overflow: hidden;
}

.home-consumer .consumer-wrap .consumer-swiper .swiper-top,
.home-consumer .consumer-wrap .consumer-swiper .swiper-bottom {
  height: 2.986667rem;
  display: flex;
  flex-wrap: nowrap;
  justify-content: flex-start;
}

.home-consumer .consumer-wrap .consumer-swiper .swiper-top {
  align-items: flex-end;
  -webkit-animation: consumer 41s linear infinite;
  animation: consumer 41s linear infinite;
  -webkit-animation-delay: 0.5s;
  animation-delay: 0.5s;
  width: 92.64rem;
}

.home-consumer .consumer-wrap .consumer-swiper .swiper-bottom {
  align-items: flex-start;
  -webkit-animation: consumer 40s linear infinite;
  animation: consumer 40s linear infinite;
  -webkit-animation-delay: 0.5s;
  animation-delay: 0.5s;
  width: 90.693333rem;
}

@keyframes consumer {
  0% {
    -webkit-transform: translateX(0) translateZ(13.333333rem);
    transform: translateX(0) translateZ(13.333333rem);
  }

  100% {
    -webkit-transform: translateX(-50%) translateZ(13.333333rem);
    transform: translateX(-50%) translateZ(13.333333rem);
  }
}

@-webkit-keyframes consumer {
  0% {
    -webkit-transform: translateX(0) translateZ(13.333333rem);
    transform: translateX(0) translateZ(13.333333rem);
  }

  100% {
    -webkit-transform: translateX(-50%) translateZ(13.333333rem);
    transform: translateX(-50%) translateZ(13.333333rem);
  }
}

.home-consumer .consumer-wrap .consumer-swiper .item {
  display: inline-block;
}

.home-consumer .consumer-wrap .consumer-swiper .item.super-species {
  width: 3.706667rem;
  height: 2.973333rem;
  background: url('../../images/super-species.png') no-repeat top center;
  background-size: 100% 100%;
}

.home-consumer .consumer-wrap .consumer-swiper .item.consuimer-360 {
  width: 3.746667rem;
  height: 1.946667rem;
  background: url('../../images/360.png') no-repeat top center;
  background-size: 100% 100%;
}

.home-consumer .consumer-wrap .consumer-swiper .item.eral {
  width: 4.28rem;
  height: 1.68rem;
  background: url('../../images/eral.png') no-repeat top center;
  background-size: 100% 100%;
}

.home-consumer .consumer-wrap .consumer-swiper .item.iqiyi {
  width: 3.893333rem;
  height: 2.453333rem;
  background: url('../../images/iqiyi.png') no-repeat top center;
  background-size: 100% 100%;
}

.home-consumer .consumer-wrap .consumer-swiper .item.be-cheery {
  width: 4.106667rem;
  height: 2.453333rem;
  background: url('../../images/be-cheery.png') no-repeat top center;
  background-size: 100% 100%;
}

.home-consumer .consumer-wrap .consumer-swiper .item.baidu {
  width: 3.946667rem;
  height: 1.946667rem;
  background: url('../../images/baidu.png') no-repeat top center;
  background-size: 100% 100%;
}

.home-consumer .consumer-wrap .consumer-swiper .item.dzdo {
  width: 3.333333rem;
  height: 1.426667rem;
  background: url('../../images/dzdo.png') no-repeat top center;
  background-size: 100% 100%;
}

.home-consumer .consumer-wrap .consumer-swiper .item.eleme {
  width: 4.346667rem;
  height: 2.106667rem;
  background: url('../../images/eleme.png') no-repeat top center;
  background-size: 100% 100%;
}

.home-consumer .consumer-wrap .consumer-swiper .item.ifeng {
  width: 5.466667rem;
  height: 2.333333rem;
  background: url('../../images/ifeng.png') no-repeat top center;
  background-size: 100% 100%;
}

.home-consumer .consumer-wrap .consumer-swiper .item.cgb {
  width: 4.12rem;
  height: 1.44rem;
  background: url('../../images/cgb.png') no-repeat top center;
  background-size: 100% 100%;
}

.home-consumer .consumer-wrap .consumer-swiper .item.gome {
  width: 2.946667rem;
  height: 1.72rem;
  background: url('../../images/gome.png') no-repeat top center;
  background-size: 100% 100%;
}

.home-consumer .consumer-wrap .consumer-swiper .item.hdl {
  width: 2.72rem;
  height: 2.72rem;
  background: url('../../images/hdl.png') no-repeat top center;
  background-size: 100% 100%;
}

.home-consumer .consumer-wrap .consumer-swiper .item.mobike {
  width: 6.346667rem;
  height: 1.88rem;
  background: url('../../images/mobike.png') no-repeat top center;
  background-size: 100% 100%;
}

.home-consumer .consumer-wrap .consumer-swiper .item.moji {
  width: 4.08rem;
  height: 1.68rem;
  background: url('../../images/moji.png') no-repeat top center;
  background-size: 100% 100%;
}

.home-consumer .consumer-wrap .consumer-swiper .item.watsons {
  width: 3.746667rem;
  height: 1.506667rem;
  background: url('../../images/watsons.png') no-repeat top center;
  background-size: 100% 100%;
}

.home-consumer .consumer-wrap .consumer-swiper .item.sg {
  width: 3.213333rem;
  height: 1.346667rem;
  background: url('../../images/sg.png') no-repeat top center;
  background-size: 100% 100%;
}

.home-consumer .consumer-wrap .consumer-swiper .item.xmly {
  width: 5.893333rem;
  height: 2.333333rem;
  background: url('../../images/xmly.png') no-repeat top center;
  background-size: 100% 100%;
}

.home-consumer .consumer-wrap .consumer-swiper .item.xdf {
  width: 3.12rem;
  height: 1.706667rem;
  background: url('../../images/xdf.png') no-repeat top center;
  background-size: 100% 100%;
}

.home-consumer .consumer-wrap .consumer-swiper .item.sina {
  width: 3.946667rem;
  height: 1.946667rem;
  background: url('../../images/sina.png') no-repeat top center;
  background-size: 100% 100%;
}

.home-consumer .consumer-wrap .consumer-swiper .item.cmb {
  width: 5.093333rem;
  height: 2.186667rem;
  background: url('../../images/cmb.png') no-repeat top center;
  background-size: 100% 100%;
}

.home-consumer .consumer-wrap .consumer-swiper .item.zbcm {
  width: 4.36rem;
  height: 1.813333rem;
  background: url('../../images/zbcm.png') no-repeat top center;
  background-size: 100% 100%;
}

.home-consumer .consumer-wrap .consumer-swiper .item.zgf {
  width: 2.72rem;
  height: 2.72rem;
  background: url('../../images/zgf.png') no-repeat top center;
  background-size: 100% 100%;
}

.home-consumer .consumer-wrap .consumer-swiper .item.aboc {
  width: 5.826667rem;
  height: 2.12rem;
  background: url('../../images/aboc.png') no-repeat top center;
  background-size: 100% 100%;
}

.home-consumer .consumer-wrap .consumer-swiper .item.qq-music {
  width: 3.333333rem;
  height: 1.573333rem;
  background: url('../../images/qq-music.png') no-repeat top center;
  background-size: 100% 100%;
}

.home-consumer .consumer-wrap .consumer-swiper .item.uc {
  width: 2.16rem;
  height: 1.386667rem;
  background: url('../../images/uc.png') no-repeat top center;
  background-size: 100% 100%;
}

.home-consumer .consumer-mask {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 9;
  background: rgba(0, 0, 0, 0);
}

.home-services {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-content: space-between;
  margin-top: 1.653333rem;
  margin-bottom: 2rem;
}

.home-services .item {
  width: 50%;
  text-align: center;
  font-size: 0.346667rem;
  color: #666;
}

.home-services .item:nth-child(3),
.home-services .item:nth-child(4) {
  margin-top: 2rem;
}

.home-services .item .item-icon {
  margin: 0 auto;
  height: 1.56rem;
}

.home-services .item .item-icon span {
  display: inline-block;
}

.home-services .item .title {
  color: #333;
  font-size: 0.506667rem;
  font-weight: 600;
  line-height: 0.706667rem;
  letter-spacing: 0.08rem;
  margin-top: 0.6rem;
}

.home-services .item .content {
  margin: 0.213333rem auto 0;
  width: 3.44rem;
  height: 1.013333rem;
  font-size: 0.346667rem;
  font-weight: 400;
  line-height: 0.506667rem;
}

.home-services .item:nth-child(1) .item-icon span {
  width: 1.52rem;
  height: 1.56rem;
  background: url('../../images/icon-1.png') no-repeat top center;
  background-size: 100% 100%;
}

.home-services .item:nth-child(2) .item-icon span {
  width: 2.133333rem;
  height: 1.333333rem;
  background: url('../../images/icon-2.png') no-repeat top center;
  background-size: 100% 100%;
}

.home-services .item:nth-child(3) .item-icon span {
  width: 1.24rem;
  height: 1.4rem;
  background: url('../../images/icon-3.png') no-repeat top center;
  background-size: 100% 100%;
}

.home-services .item:nth-child(4) .item-icon span {
  width: 1.333333rem;
  height: 1.36rem;
  background: url('../../images/icon-4.png') no-repeat top center;
  background-size: 100% 100%;
}

.home-footer {
  height: 6.08rem;
  background-color: #202639;
  padding: 0.44rem 0.546667rem;
  font-size: 0.266667rem;
  font-weight: 400;
  line-height: 0.426667rem;
  color: rgba(255, 255, 255, 0.7);
}

.home-footer p {
  margin-top: 0.213333rem;
}

.home-footer span {
  display: block;
  width: 100%;
  height: 0.013333rem;
  background-color: rgba(255, 255, 255, 0.2);
  margin: 0.533333rem auto 0.32rem;
}

.home-footer a {
  color: rgba(255, 255, 255, 0.7);
}

.question-button {
  position: fixed;
  right: -0.2rem;
  width: 2.426667rem;
  height: 2.426667rem;
  top: 8.8rem;
  background: url('../../images/icon-call.png') no-repeat top center;
  background-size: 100% 100%;
  z-index: 100;
}

.home-button {
  position: fixed;
  left: 0;
  bottom: 0;
  height: 1.333333rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  z-index: 999;
  font-size: 0.453333rem;
}

.home-button .issue-button,
.home-button .tel-button,
.home-button .baipi-button,
.home-button .tryout-button {
  height: 1.333333rem;
  line-height: 1.333333rem;
  width: 50%;
  text-align: center;
  color: #fff;
}

.home-button .issue-button p,
.home-button .tel-button p,
.home-button .baipi-button p,
.home-button .tryout-button p {
  font-size: 0.32rem;
}

.home-button .issue-button {
  background: #56bcf9;
}

.home-button .issue-button span {
  display: inline-block;
  width: 0.466667rem;
  height: 0.4rem;
  background: url('../../images/icon_issue.png') no-repeat top center;
  background-size: 100% 100%;
  margin-right: 0.16rem;
}

.home-button .baipi-button,
.home-button .tel-button {
  background: #ff8f24;
  line-height: 1.2em;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.home-button .baipi-button span,
.home-button .tel-button span {
  display: inline-block;
  width: 0.56rem;
  height: 0.586667rem;
  background: url('../../images/icon_tel.png') no-repeat top center;
  background-size: 100% 100%;
  vertical-align: sub;
  margin-right: 0.16rem;
}

.home-button .tryout-button {
  background: #006cff;
}

.home-button .tryout-button span {
  width: 0.586667rem;
  height: 0.746667rem;
  display: inline-block;
  background: url('../../images/icon_tryout.png') no-repeat top center;
  background-size: 100% 100%;
  vertical-align: sub;
  margin-right: 0.16rem;
}

.home-button .popover {
  position: absolute;
  width: 2.626667rem;
  height: 0.933333rem;
  line-height: 0.933333rem;
  text-align: center;
  background: #006cff;
  color: #fff;
  font-size: 0.426667rem;
  border-radius: 0.466667rem;
  right: 1.333333rem;
  top: -1.2rem;
  box-shadow: 0.013333rem 0.026667rem 0.066667rem #006cff;
  opacity: 0;
  -webkit-animation: popover-show 6s linear infinite;
  animation: popover-show 6s linear infinite;
  -webkit-animation-delay: 3s;
  animation-delay: 3s;
}

.home-button .popover .popover-arrow {
  position: absolute;
  width: 0.266667rem;
  height: 0.266667rem;
  background-color: #006cff;
  z-index: 0;
  -webkit-transform: rotate(45deg) translateX(-50%);
  transform: rotate(45deg) translateX(-50%);
  left: 50%;
}

@-webkit-keyframes popover-show {
  0% {
    opacity: 1;
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }

  10% {
    opacity: 1;
    -webkit-transform: translateY(-0.266667rem);
    transform: translateY(-0.266667rem);
  }

  20% {
    opacity: 1;
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }

  30% {
    opacity: 1;
    -webkit-transform: translateY(-0.266667rem);
    transform: translateY(-0.266667rem);
  }

  31%, 100% {
    opacity: 0;
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
}

@keyframes popover-show {
  0% {
    opacity: 1;
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }

  10% {
    opacity: 1;
    -webkit-transform: translateY(-0.266667rem);
    transform: translateY(-0.266667rem);
  }

  20% {
    opacity: 1;
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }

  30% {
    opacity: 1;
    -webkit-transform: translateY(-0.266667rem);
    transform: translateY(-0.266667rem);
  }

  31%, 100% {
    opacity: 0;
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
}

.issue-modal {
  text-align: center;
  position: fixed;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  z-index: 1099;
  background: rgba(0, 0, 0, 0.7);
}

.issue-modal-content {
  width: 7.626667rem;
  height: 7.706667rem;
  margin: 2.373333rem auto 0;
  background: #fff;
  border-radius: 0.266667rem;
  position: relative;
  padding-top: 0.506667rem;
}

.issue-modal-content .icon {
  width: 0.986667rem;
  height: 0.986667rem;
  position: absolute;
  bottom: -1.84rem;
  left: 3.4rem;
  background: url('../../images/issue-modal-close.png') no-repeat top center;
  background-size: 100% 100%;
}

.issue-modal-content h3 {
  width: 100%;
  text-align: center;
  font-size: 0.426667rem;
  font-family: PingFangSC-Semibold;
  font-weight: 600;
  color: #333;
  line-height: 0.6rem;
  margin: 0 auto 0.08rem;
}

.issue-modal-content li {
  width: 5.52rem;
  margin: 0.453333rem auto 0;
  font-size: 0.426667rem;
  line-height: 1.28rem;
  color: #999999;
  border: 0.026667rem solid #999999;
  border-radius: 0.133333rem;
  position: relative;
  text-align: left;
  padding-left: 1.093333rem;
}

.issue-modal-content li::before {
  content: '';
  width: 0.16rem;
  height: 0.16rem;
  border: 0.16rem solid #D8D8D8;
  border-radius: 50%;
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  left: 0.4rem;
}

.issue-modal-content li.active {
  color: #00A9F1;
  border: 0.026667rem solid #00A9F1;
  background: rgba(34, 189, 255, 0.11);
}

.issue-modal-content li.active::before {
  border: 0.16rem solid #00A9F1;
}

.issue-modal-content p {
  font-size: 0.4rem;
  color: #22BDFF;
  margin: 0.906667rem auto 0.36rem;
}

.issue-modal-content .issue-modal-button {
  display: block;
  width: 6.613333rem;
  height: 1.093333rem;
  border-radius: 0.706667rem;
  margin: 0 auto;
  color: #fff;
  background: #22BDFF;
  font-size: 0.4rem;
  line-height: 1.093333rem;
}