.NewHome {
  margin-bottom: 1.546667rem;
}

.picture5 {
  margin-top: -0.053333rem;
}

.picture {
  width: 100%;
}

.picture img {
  width: 100%;
  margin-top: -0.026667rem;
  min-height: 5.12rem;
  height: auto;
}

.form {
  margin: 0.266667rem;
  border-radius: 0.533333rem;
  background-color: #fff;
  padding-top: 0.586667rem;
}

.form p {
  font-size: 0.533333rem;
  font-family: AlibabaPuHuiTi-Medium, AlibabaPuHuiTi;
  font-weight: bold;
  color: #000000;
  line-height: 0.666667rem;
  text-align: center;
}

.form .application {
  border-bottom: 0.013333rem solid #fff;
  width: 100%;
  border-radius: 0.533333rem;
  min-height: 6.666667rem;
  overflow: hidden;
}

.form .application .am-list .check-list .check-item {
  width: 4rem;
}

.form .application .am-list .am-button {
  width: 97%;
}

.form .submit-result .go-home {
  display: none;
}

.form .submit-result {
  position: absolute;
}

.long-wrap img {
  width: 100%;
  border-radius: 0.933333rem;
}

.btn-wrap {
  position: fixed;
  bottom: 0;
  left: 0;
  display: flex;
  width: 100%;
  height: 1.546667rem;
  background: linear-gradient(90deg, #F09548 0%, #E73F76 100%);
  align-items: center;
  justify-content: center;
}

.btn-wrap .btn {
  text-align: center;
  width: 4.506667rem;
  height: 1.12rem;
  background: linear-gradient(102deg, #FCF2D7 0%, #FBE7AB 100%);
  border-radius: 0.613333rem;
  font-size: 0.48rem;
  font-family: AlibabaPuHuiTi-Medium, AlibabaPuHuiTi;
  font-weight: 500;
  color: #D02D40;
  line-height: 0.666667rem;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  margin-left: 0.32rem;
  position: relative;
}

.btn-wrap .btn p {
  font-size: 0.213333rem;
  font-family: AlibabaPuHuiTi-Regular, AlibabaPuHuiTi;
  font-weight: 400;
  color: #D02D40;
  line-height: 0.293333rem;
}

.btn-wrap .btn-try .icon {
  width: 3.013333rem;
  height: 1.52rem;
  background-image: url('//yun.duiba.com.cn/duiba-h5-website-node/newhome/icon-1.png');
  background-size: 100% 100%;
  position: absolute;
  right: 0.666667rem;
  top: -1.6rem;
  opacity: 0;
  -webkit-animation: popover-show 6s linear infinite;
  animation: popover-show 6s linear infinite;
  -webkit-animation-delay: 3s;
  animation-delay: 3s;
}

body.newhome {
  background-color: #3D48F4 !important;
}

@-webkit-keyframes popover-show {
  0% {
    opacity: 1;
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }

  10% {
    opacity: 1;
    -webkit-transform: translateY(-0.266667rem);
    transform: translateY(-0.266667rem);
  }

  20% {
    opacity: 1;
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }

  30% {
    opacity: 1;
    -webkit-transform: translateY(-0.266667rem);
    transform: translateY(-0.266667rem);
  }

  31%, 100% {
    opacity: 0;
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
}

@keyframes popover-show {
  0% {
    opacity: 1;
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }

  10% {
    opacity: 1;
    -webkit-transform: translateY(-0.266667rem);
    transform: translateY(-0.266667rem);
  }

  20% {
    opacity: 1;
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }

  30% {
    opacity: 1;
    -webkit-transform: translateY(-0.266667rem);
    transform: translateY(-0.266667rem);
  }

  31%, 100% {
    opacity: 0;
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
}