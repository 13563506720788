.application {
  width: 10rem;
  padding: 0.4rem 0.506667rem 0 0.506667rem;
  position: relative;
}

.application .am-list-body::after,
.application .am-list-body::before {
  display: none !important;
}

.application .am-list .am-list-item.am-input-item {
  height: 1.6rem;
  padding-left: 0;
  font-size: 0.373333rem;
  color: #999;
}

.application .am-list .am-list-item.am-input-item .am-input-label {
  font-size: 0.373333rem;
  color: #999;
  width: 1.866667rem;
}

.application .am-list .am-list-item.am-input-item .am-input-control {
  height: 100%;
}

.application .am-list .am-list-item.am-input-item .am-input-control input {
  height: 100%;
  font-size: 0.426667rem;
  color: #333;
}

.application .am-list .am-list-item.am-input-item .am-input-clear {
  width: 0.4rem;
  height: 0.4rem;
  background-size: 100% 100%;
}

.application .am-list .am-list-item.am-input-item .am-input-error-extra {
  width: 0.4rem;
  height: 0.4rem;
  background-size: 100% 100%;
}

.application .am-list .real-name.active .am-list-line::after,
.application .am-list .phone.active .am-list-line::after,
.application .am-list .company-name.active .am-list-line::after {
  background-color: #3387FF;
  height: 3PX;
}

.application .am-list .check-list {
  margin-top: 0.533333rem;
}

.application .am-list .check-list h4 {
  width: 100%;
  height: 0.533333rem;
  font-size: 0.373333rem;
  font-weight: 600;
  color: #999999;
  line-height: 0.533333rem;
}

.application .am-list .check-list .check-item {
  width: 4.293333rem;
  height: 0.946667rem;
  border-radius: 0.48rem;
  font-size: 0.373333rem;
  font-weight: 400;
  color: #666666;
  border: 0.026667rem solid #D5D8DE;
  line-height: 0.946667rem;
  text-align: center;
  display: inline-block;
  margin-top: 0.4rem;
}

.application .am-list .check-list .check-item:nth-child(even) {
  margin-right: 0.373333rem;
}

.application .am-list .check-list .check-item.active {
  border: 0.026667rem solid #1272FF;
  color: #1272FF;
}

.application .am-list .am-input-error-extra {
  display: none;
}

.application .am-list .am-list-item.am-textarea-item.area-con {
  width: 8.986667rem;
  min-height: 2.133333rem;
  height: auto;
  border-radius: 0.106667rem;
  border: 1PX solid #cfcfcf;
  margin-top: 0.4rem;
}

.application .am-list .am-list-item.am-textarea-item.area-con .am-textarea-control {
  padding-bottom: 0.32rem;
}

.application .am-list .am-list-item.am-textarea-item.area-con .am-textarea-control textarea {
  color: #333;
  font-size: 0.426667rem;
  padding: 0.053333rem 0;
  line-height: 0.6rem;
}

.application .am-list .am-list-item.am-textarea-item.area-con .am-textarea-count {
  font-size: 0.4rem;
}

.application .am-list .am-list-item.am-textarea-item.area-con .am-textarea-count span {
  color: #bbb;
}

.application .am-list .am-button {
  width: 9.146667rem;
  height: 1.466667rem;
  background: #1272FF;
  border-radius: 0.16rem;
  line-height: 1.466667rem;
  margin-top: 1.066667rem;
  color: #fff;
  font-size: 0.48rem;
  font-weight: 600;
  margin-bottom: 0.466667rem;
}

.application .am-list .am-button::before {
  display: none !important;
}

.application .baipi .am-list .am-button {
  background: #ff8f24;
}

.submit-result {
  text-align: center;
  position: fixed;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  z-index: 9;
  background: #fff;
}

.submit-result .icon {
  width: 1.76rem;
  height: 2.133333rem;
  margin: 3.413333rem auto 0;
  background: url('../../images/success-icon.png') no-repeat top center;
  background-size: 100% 100%;
}

.submit-result h3 {
  width: 100%;
  text-align: center;
  height: 0.533333rem;
  font-size: 0.613333rem;
  font-family: PingFangSC-Semibold;
  font-weight: 600;
  color: #3387ff;
  line-height: 0.533333rem;
  margin: 0.653333rem auto 0;
}

.submit-result p {
  margin: 0.773333rem auto 0;
  width: 6.053333rem;
  height: 1.066667rem;
  font-size: 0.4rem;
  font-weight: 400;
  color: #333333;
  line-height: 0.533333rem;
}

.submit-result .go-home {
  width: 8.8rem;
  height: 1.226667rem;
  line-height: 1.226667rem;
  background: linear-gradient(5deg, #3387ff 0%, #33f4ff 100%, #33f4ff 100%);
  border-radius: 0.613333rem;
  margin: 1.866667rem auto 0;
  color: #fff;
  font-size: 0.426667rem;
  display: block;
}

.am-toast-notice.am-toast-notice-closable {
  width: 5.333333rem;
}

.am-toast-notice.am-toast-notice-closable .am-toast-notice-content {
  width: 100%;
  font-size: 0.426667rem;
}

.am-toast-notice.am-toast-notice-closable .am-toast-notice-content .am-toast-text {
  border-radius: 0.2rem;
}

.pc #newBridge {
  display: none;
}